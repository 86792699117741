.contact__container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content horizontally */
    padding-bottom: 3rem;
    text-align: center; /* Centers text within the container */
}


.contact__title{
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1-5);
}



.contact__info {
    display: grid;
    row-gap: 1rem;
    grid-template-columns: 300px;
    justify-content: center; /* Centers the info cards */
}

.contact__card-icon{
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-25);
}

.contact__card-title,
.contact__card-data{
    font-size: var(--small-font-size);
}

.contact__card-title{
    font-weight: vat(--font-medium);
}

.contact__card-data{
    display: block;
    margin-bottom: var(--mb-0-75);
}

.contact__button{
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
}

.contact__button-icon{
    font-size: 1rem;
    transition: 0.3s;
}

.contact__button:hover{
    transform: translate(0.25rem);
}

.contact__card{
    background-color: var(--container-color);
    border: 1px solid rgba(0,0,0,0.1);
    padding: 1rem;
    border-radius: 0.75rem;
    text-align: center;
}

/*============BREAKPOINT==========*/

/*For larger devices*/
@media screen and (max-width: 992px) {
   .contact__container{
    column-gap: 3rem;
   }

   }
   
   
   /*For medium devices*/
   
   @media screen and (max-width: 768px){
    .contact__container{
        grid-template-columns: 1fr;
        row-gap: 3rem;
    }

    .contact__info{
        justify-content: center;
    }

}

@media screen and (max-width: 576px){
    .contact__info{
        grid-template-columns: 1fr;
    }


}
   
   /*For smaller devices*/
   @media screen and (max-width: 350px) {
    
   
   }
   