
/*======GOOGLE FONTS========*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,100&display=swap');

/*======VATIABLE CSS=======*/
:root{
  
  --header-height:3rem;

  /*======COLOR========*/
  /*color mode HSL(hue, saturation, lightness)*/
  --hue:0;
  --sat:0%;
  --title-color: hsl(var(--hue),var(--sat),20%);
  --title-color-dark:hsl(var(--hue), var(--sat), 0%);
  --text-color:hsl(var(--hue), var(--sat), 46%);
  --body-color:hsl(var(--hue), var(--sat), 98%);
  --container-color: #fff;
  



  /*=======Fonts and typography========*/
  --body-font: "Poppins", sans-serif;

  /*.5rem = 8px | 1rem = 16px */
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /*=======FONT WEIGHT========*/
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /*=====Margens Bottom*/
  /*.5rem = 8px | 1rem= 16px */
  --mb-0-25: 0.25rem;
  --mb-05-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb2-5: 2.5rem;
  --mb-3: 3rem;
  
  /*z index*/
  --z-tooltios: 10;
  --z-fixed:100;
  --z-modal:1000;
}

/*Responsive Typography*/
@media screen and (max-width: 992px) {
  :root{
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 2rem;
    --h3-font-size: 3rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
  
}

/*============BASE==========*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

html{
  scroll-behavior: smooth;
}


body,
button,
input,
textarea{
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body{
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,h2,h3{
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
}

button{
  cursor: pointer;
  border: none;
  outline: none;
}

image{
  max-width: 100%;
  height: auto;
}
/*============REUSABLE CSS CLASSES==========*/
.section{
  padding: 6rem 0 2rem;
}

.section__title{
  font-size: var(--h1-font-size);
  color: --title-color;
}


.section__subtitle{
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
}

.section__title,
.section__subtitle{
  text-align: center;
}
/*============LAYOUT==========*/
.container{
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.grid{
  display: grid;
  gap: 1.5rem;
}


/*============BUTTONS==========*/
.button{
  display:inline-block;
  background-color: var(--title-color);
  color: var(--container-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
}

.button:hover{
  background-color: var(--title-color-dark);
}

.button__icon{
  margin-left:var(--mb-0-5);
}

.button--flex{
  display: inline-flex;
  align-items: center;
}



/*============BREAKPOINT==========*/

/*For larger devices*/
@media screen and (max-width: 992px) {
  .container{
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
  }

  .button{
    padding: 1rem 1.75rem;
  }

  .button__icon{
    width: 22px;
    height: 22px;
  }
  
}


/*For medium devices*/
@media screen and (max-width: 768px) {
  .body{
  margin: 0 0 var(--header-height) 0;
  }

  .section{
    padding: 2rem 0 4rem
  }

  .section__subtitle{
    margin-bottom: var(--mb-3);
  }
}

@media screen and (max-width: 576px){
  
}

/*For smaller devices*/
@media screen and (max-width: 350px) {
  :root{
    --big-font-size: 2.25rem
  }

  .container{
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
    }

}


