:root {
    --z-fixed: 1000; /* Sets a fixed z-index value */
    --container-color: #fff; /* Sets the container background color to white */
    --header-heights: 4rem; /* Sets the header height */
    --title-color: #333; /* Sets the title color */
    --font-medium: 500; /* Sets the medium font weight */
    --body-color: #f0f0f0; /* Sets the body background color */
    --small-font-size: 0.875rem; /* Sets the small font size */
    --title-color-dark: #000; /* Sets the dark title color */
}

.header {
    width: 100%; /* Sets the header width to 100% */
    position: fixed; /* Fixes the header position */
    top: 0; /* Positions the header at the top */
    left: 0; /* Positions the header at the left */
    z-index: var(--z-fixed); /* Uses the --z-fixed variable for z-index */
    background-color: var(--body-color); /* Uses the --body-color variable for background color */
}

.nav {
    height: calc(var(--header-heights) + 1.5rem); /* Calculates the nav height */
    display: flex; /* Displays the nav as flexbox */
    justify-content: space-between; /* Distributes space between items */
    align-items: center; /* Aligns items to the center */
    column-gap: 1rem; /* Sets the gap between columns to 1rem */
}

.nav__logo,
.nav__toggle {
    color: var(--title-color); /* Sets the text color using the --title-color variable */
    font-weight: var(--font-medium); /* Sets the font weight using the --font-medium variable */
}

.nav__list {
    display: flex; /* Displays the list items as flexbox */
    column-gap: 2rem; /* Sets the gap between columns to 2rem */
}

.nav__link {
    display: flex; /* Displays the link items as flexbox */
    flex-direction: column; /* Arranges the flex items in a column */
    align-items: center; /* Centers the items along the cross axis */
    font-size: var(--small-font-size); /* Sets the font size using the --small-font-size variable */
    color: var(--title-color); /* Sets the text color using the --title-color variable */
    font-weight: var(--font-medium); /* Sets the font weight using the --font-medium variable */
    transition: .3s; /* Sets the transition duration to 0.3 seconds */
}

.nav__icon,
.nav__close,
.nav__toggle {
    display: none; /* Hides these elements */
}

/* Active Link */
.active-link,
.nav__link:hover {
    color: yellow; /* Sets the color to dark title color on hover or active link */
}


.scroll-header{
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}


/* ============ BREAKPOINT ========== */
/* For medium devices */
@media screen and (max-width: 768px) {
  .header {
    top: initial; /* Resets the top position */
    bottom: 0; /* Positions the header at the bottom */
  }

  .nav {
    height: var(--header-height); /* Sets the nav height using the --header-height variable */
  }

  .nav__menu {
    position: fixed; /* Fixes the menu position */
    bottom: -100%; /* Positions the menu at the bottom */
    left: 0; /* Positions the menu at the left */
    width: 100%; /* Sets the menu width to 100% */
    background-color: var(--body-color); /* Uses the --body-color variable for background color */
    padding: 2rem 1.5rem 4rem; /* Adds padding to the menu */
    box-shadow: 0 -1px 4px rgba(0,0,0,0.15); /* Adds a box shadow to the menu */
    border-radius: 1.5rem 1.5rem 0 0; /* Rounds the top corners of the menu */
    transition: .3s; /* Sets the transition duration to 0.3 seconds */
  }

  /*Show Menu*/
  .show-menu{
    bottom: 0;
  }

  .nav__list {
    display: grid; /* Displays the list items as a grid */
    grid-template-columns: repeat(3, 1fr); /* Creates three equal-width columns */
    gap: 2rem; /* Sets the gap between grid items to 2rem */
  }

  .nav__icon {
    font-size: 1.2rem; /* Sets the font size of the icons */
  }

  .nav__close {
    position: absolute; /* Positions the close button absolutely */
    right: 1.3rem; /* Positions the close button 1.3rem from the right */
    bottom: .5rem; /* Positions the close button 0.5rem from the bottom */
    cursor: pointer; /* Changes the cursor to a pointer when hovering over the close button */
    color: var(--title-color); /* Sets the color of the close button using the --title-color variable */
  }

  .nav__close:hover {
    color: var(--title-color-dark); /* Sets the hover color using the --title-color-dark variable */
  }

  .nav__toggle{
    font-size: 1.1rem;
    cursor: pointer;
  }
  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: block; /* Displays these elements as block elements */
  }
}

/*For smaller devices*/
@media screen and (max-width: 992px) {
  .nav__menu{
    padding: 2rem 0.25rem 4rem;
  } 

  .nav__list{
    column-gap: 0;
  }
}